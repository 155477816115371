<template>
  <van-row class="goods-grid">
    <template v-if="list && list.length">
      <van-col
        :key="index"
        @click="showInfo(row)"
        class="col"
        span="12"
        v-for="(row, index) in list"
      >
        <van-image :src="row.titlepic" class="w100 thumbnail" lazy-load>
          <template v-slot:loading>
            <van-loading size="48" type="spinner" vertical>加载中...</van-loading>
          </template>
          <template v-slot:error>
            <van-image class="w100" src="http://75pop.com/images/no_picture.gif" />
          </template>
        </van-image>
        <div @click="showInfo(row)" class="goods-name" v-html="row.title"></div>
        <van-row class>
          <van-col span="14" class="news-time">{{ row.newstime }}</van-col>
          <van-col span="10" class="danger tr">
            <van-icon name="gem"></van-icon>
            {{ row.buyfen }}
          </van-col>
        </van-row>
      </van-col>
    </template>
    <van-empty description="暂无信息" v-else />
  </van-row>
</template>

<script>
import Vue from "vue";
import { Image, Col, Row, Empty, Loading, Lazyload } from "vant";
Vue.use(Image)
  .use(Col)
  .use(Row)
  .use(Empty)
  .use(Loading)
  .use(Lazyload);

export default {
  props: ["list"],
  data() {
    return {};
  },
  methods: {}
};
</script>

<style scoped>
.goods-grid {
  background-color: #ffffff;
  padding: 0 5px;
}
.goods-grid .col {
  padding: 6px;
  margin-top: 5px;
}
.goods-grid .van-image {
  border: 1px solid #999;
  cursor: pointer;
}
.goods-grid .goods-name {
  font-size: 12px;
  cursor: pointer;
  min-height: 32px;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 5px;
}
.goods-grid .goods-price {
  width: 100%;
  text-align: right;
  padding: 3px 20px;
  color: #f60;
}
.goods-grid .error-img {
  min-height: 100px;
}
.goods-grid .news-time {
  color: #999;
  text-align: left;
}
</style>